import React, { useContext, useState } from 'react'
import Router, { withRouter } from 'next/router'
import PropTypes from 'prop-types'
import { Modal } from '@mch-group/uikit-components'
import styled from 'styled-components'
import { px2rem, mq, StyleModalBottom, hiDPIpx } from '@utils'
import ModalTopBar from '@components/ModalTopBar'
import useMyCollectionsData from '@modules/collections/hooks/useMyCollectionsData'
import useCopyMoveCollectionDetail from '@modules/collections/hooks/useCopyMoveCollectionDetail'
import { cssClasses } from '@constants'
import { useCollectionsContext } from '@modules/collections/contexts/useCollectionsContext'
import { CollectionCardSmall } from '@components/collections'
import SelectorControls from '@components/SelectorControls'
import { Collections as CollectionsService } from '@services'
import { SnackBarNotificationContext } from '@providers/SnackBarNotification'
import { LoginContext } from '@providers/Login'
import { CollectionFormContext } from './collection-form'

const CopyMoveToCollection = () => {
  const { showNotification } = useContext(SnackBarNotificationContext)
  const { showModal, modalData, hideModal } = useCopyMoveCollectionDetail()
  const { showLogin } = useContext(LoginContext)
  // @ts-ignore
  const [{ labels }, dispatch] = useCollectionsContext()

  // @ts-ignore
  const { showForm } = React.useContext(CollectionFormContext)
  const {
    myCollections,
    entity,
    setEntity,
    myFavoritesCollection,
    membersValidating,
    myCollectionsValidating,
    isEntityFavorited,
    setActive
  } = useMyCollectionsData(false, 99)
  const [visible, setVisible] = useState(false)
  const [isFavSelected, setIsFavSelected] = useState(isEntityFavorited)
  // This variable controls the enable/disable status of the save button. Should this be a formik, there would be
  // a "dirty" prop. But it's not, so it has to be controlled manually.
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)
  const { Collections: CollectionsLabels, CollectionForms: CollectionFormsLabels } = labels || {}
  const [successMessage, setSuccessMessage] = useState(null)
  const [titleLabel, setTitleLabel] = useState(null)
  const [btnText, setBtnText] = useState(null)
  const [selectedItems, setSelectedItems] = useState(null)
  const [modalFor, setModalFor] = useState({ save: true, copy: false, move: false })
  const mID = 'add-to-collection'

  // Favorites
  React.useEffect(() => {
    setIsFavSelected(isEntityFavorited)
  }, [isEntityFavorited])

  const showCopyToCollectionModal = React.useCallback(
    (id, type, items, messageOK = 'Items copy to Collection') => {
      // @ts-ignore
      setSuccessMessage(`${items.length} ${messageOK}`)
      showLogin({
        onLoginSuccessCallback: () => {
          // @ts-ignore
          setEntity({ id, type })
          setVisible(true)
          setActive(true)
          setTitleLabel(CollectionFormsLabels?.copyToLabel)
          setModalFor({ save: false, copy: true, move: false })
          setBtnText(CollectionFormsLabels?.copyButtonLabel)
          setSelectedItems(items)
        }
      })
    },
    [CollectionFormsLabels, setActive, setEntity, showLogin]
  )
  const showMoveToCollectionModal = React.useCallback(
    (id, type, items, messageOK = 'Items move to Collection') => {
      // @ts-ignore
      setSuccessMessage(`${items.length} ${messageOK}`)
      showLogin({
        onLoginSuccessCallback: () => {
          // @ts-ignore
          setEntity({ id, type })
          setVisible(true)
          setActive(true)
          setTitleLabel(CollectionFormsLabels?.moveToLabel)
          setModalFor({ save: false, copy: false, move: true })
          setBtnText(CollectionFormsLabels?.moveButtonLabel)
          setSelectedItems(items)
        }
      })
    },
    [CollectionFormsLabels, setActive, setEntity, showLogin]
  )

  const closeForm = () => {
    // Will stop any hooks from re-checking stuff
    setActive(false)
    // Closes the popup
    setVisible(false)
    // Resets the latest current Entity
    setEntity(null)
    setIsSaveEnabled(false)
  }

  const moveData = async (entityIds) => {
    // @ts-ignore
    const dataToSend = selectedItems.map((item) => ({
      addTo: entityIds,
      entityId: item.entityId.toString(),
      entityType: item.entityType,
      // @ts-ignore
      removeFrom: [entity.id],
      subEntityId: item.subEntityId
    }))
    const res = await CollectionsService.update({ items: dataToSend })
    modalData.updateItems()
    return res.ok && res.status === 200
  }
  const copyData = async (entityIds) => {
    // @ts-ignore
    const dataToSend = selectedItems.map((item) => ({
      addTo: entityIds,
      entityId: item.entityId.toString(),
      entityType: item.entityType,
      subEntityId: item.subEntityId
    }))
    const res = await CollectionsService.update({ items: dataToSend })
    return res.ok && res.status === 200
  }
  React.useEffect(() => {
    const { forModal, ...rest } = modalData
    if (showModal === true && forModal === 'Copy') {
      showCopyToCollectionModal(
        rest.id,
        rest.type,
        rest.selecteditems,
        CollectionFormsLabels?.itemsCopyToMsgConfirmation
      )
    }
    if (showModal === true && forModal === 'Move') {
      showMoveToCollectionModal(
        rest.id,
        rest.type,
        rest.selecteditems,
        CollectionFormsLabels?.itemsMoveToMsgConfirmation
      )
    }
  }, [showModal, modalData, showCopyToCollectionModal, showMoveToCollectionModal, CollectionFormsLabels])
  React.useEffect(() => {
    const isSeleted = myCollections.some(({ hasSelectedEntityId }) => hasSelectedEntityId === true)
    isSaveEnabled !== isSeleted && !isFavSelected && setIsSaveEnabled(isSeleted)
  }, [myCollections, isSaveEnabled, isFavSelected])
  // @ts-ignore
  const filterCollection = myCollections.filter(({ id }) => id !== entity?.id)
  // @ts-ignore
  const isFavCollection = entity?.id?.includes('favs') // This is used to check if the current collection is fav or not
  return (
    <>
      {visible && !myCollectionsValidating && !membersValidating && (
        <div id='modal'>
          {/* @ts-ignore */}
          <ModalStyled variant='sheet' size='md' show aria-label={titleLabel} className={mID}>
            {/* @ts-ignore */}
            <ModalTopBar title={titleLabel} onClick={() => closeForm()} />
            <ModalContentStyled>
              <div className={cssClasses.CMS_MODULE}>
                {/* Favorites */}
                {!isFavCollection
                  ? myFavoritesCollection?.items && (
                    <CollectionCardSmall
                      onClick={() => {
                        // @ts-ignore
                        setIsFavSelected(!isFavSelected)
                        setIsSaveEnabled(true)
                      }}
                      item={myFavoritesCollection.items[0]}
                      isChecked={isFavSelected}
                    />
                  )
                  : null}
                {/* Collections */}
                {filterCollection?.map(
                  (item) =>
                    item && (
                      <CollectionCardSmall
                        onClick={() => {
                          dispatch({ type: 'TOGGLE_ITEM_SELECTION', id: item.id })
                        }}
                        key={item.id}
                        item={item}
                        isChecked={item.hasSelectedEntityId}
                      />
                    )
                )}
              </div>
              {/* No infinite scroll for now */}
            </ModalContentStyled>
            <SelectorControlsStyled
              resetBtnActive
              disabled={myCollectionsValidating || membersValidating || !isSaveEnabled}
              onChange={async () => {
                setIsSaveEnabled(false)
                const addTo = filterCollection?.filter((item) => item.hasSelectedEntityId).map((item) => item.id)
                const removeFrom = filterCollection?.filter((item) => !item.hasSelectedEntityId).map((item) => item.id)
                if (isFavSelected) {
                  addTo.push(myFavoritesCollection.items[0].id)
                } else {
                  myFavoritesCollection.length > 0 && removeFrom.push(myFavoritesCollection.items[0].id)
                }
                const { move, copy } = modalFor
                let content = ''
                if (move) {
                  const isMoved = await moveData(addTo)
                  if (isMoved) {
                    hideModal()
                  }
                  // @ts-ignore
                  content = isMoved ? successMessage : 'Something went wrong,Please try again'
                }
                if (copy) {
                  const isCopied = await copyData(addTo)
                  // @ts-ignore
                  content = isCopied ? successMessage : 'Something went wrong,Please try again'
                }
                showNotification(true, {
                  autohide: true,
                  position: 'top-end',
                  actionText: 'View',
                  onAction: () =>
                    Router.push(
                      {
                        pathname: '/'
                      },
                      CollectionsLabels.addToCollectionCallbackURL
                    ),
                  message: content,
                  className: 'position-fixed',
                  type: 'default'
                })
                closeForm()
              }}
              onClickReset={() => {
                setIsSaveEnabled(true)
                showForm({
                  setNewCollectionSelected: true
                })
              }}
              labels={{
                reset: CollectionsLabels?.createNewCollectionLabel,
                // @ts-ignore
                apply: btnText
              }}
              widerSubmit
            />
          </ModalStyled>
        </div>
      )}
    </>
  )
}

const ModalStyled = styled(Modal)`
  ${mq.lessThan('largestTablet')`
    ${StyleModalBottom}
  `}
`

const ModalContentStyled = styled(Modal.Body)`
  padding: 0 !important;

  & > div.ui.list {
    margin: 0 0 0 ${px2rem(6)} !important;

    & > .item .content {
      padding-left: ${px2rem(14)} !important;
    }

    & > div[role='listitem'] {
      ${mq.between('tablet', 'largestTablet')`
        padding-left: ${hiDPIpx(16)};
        padding-right: ${hiDPIpx(16)};
        padding-bottom: 0;
      `}

      ${mq.lessThan('largestMobile')`
        padding-left: ${px2rem(16)};
        padding-right: ${px2rem(16)};
        padding-bottom: 0;
      `}
    }
  }

  ${mq.lessThan('largestTablet')`
    &.scrolling.content {
      padding: 0 !important;
    }
    & > div.ui.list {
      margin: 0 !important;
    }
  `}
`

const SelectorControlsStyled = styled(SelectorControls)`
  border-top: 1px solid var(--bs-mch-galleries);
  padding: ${px2rem(22)} ${px2rem(22)} ${px2rem(22)} ${px2rem(23)};

  span[role='button'] {
    color: var(--bs-mch-gray-500);
  }
`

CopyMoveToCollection.propTypes = {
  pathname: PropTypes.string,
  router: PropTypes.object,
  editPreviewCollectionItems: PropTypes.array
}

// @ts-ignore --> Probably withRouter is not necessary
export default withRouter(CopyMoveToCollection)
