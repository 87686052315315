import dynamic from 'next/dynamic'

import DefaultLoadingComponent from './Loading'
import HeadlineTitle from '../reusable-modules/HeadlineTitle'

const cmsComponents = {
  ResetPasswordForm: dynamic(() => import(/* webpackChunkName: 'ResetPassword' */ '@modules/ResetPassword'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  PromotionalBanner: dynamic(() => import(/* webpackChunkName: 'PromotionalBanner' */ '@modules/PromotionalBanner'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  News: dynamic(() => import(/* webpackChunkName: 'News' */ '@modules/News'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  Information: dynamic(() => import(/* webpackChunkName: 'Information' */ '@modules/Information'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  HeadlineTitle,
  EVENTS_Group: dynamic(() => import(/* webpackChunkName: 'EVENTS_Group' */ '@modules/EventsGroup'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  Signpost: dynamic(() => import(/* webpackChunkName: 'Signpost' */ '@modules/Signpost'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  H2A: dynamic(() => import(/* webpackChunkName: 'H2A' */ '../reusable-modules/H2A'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  Text: dynamic(() => import(/* webpackChunkName: 'Text' */ '../reusable-modules/Text'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  TSBM: dynamic(
    () => import(/* webpackChunkName: 'ResetPasswordCRMUser' */ '@modules/TSBM'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),
  SecondaryHeader: dynamic(
    () => import(/* webpackChunkName: 'SecondaryHeader' */ '../reusable-modules/SecondaryHeader'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),
  REM: dynamic(() => import(/* webpackChunkName: 'REM' */ '@modules/EditorialModule'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  Content: dynamic(() => import(/* webpackChunkName: 'Content' */ '@modules/Content'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  Partners: dynamic(() => import(/* webpackChunkName: 'Partners' */ '../reusable-modules/Partners'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  Separator: dynamic(() => import(/* webpackChunkName: 'Separator' */ '../reusable-modules/Separator'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  Promotional: dynamic(() => import(/* webpackChunkName: 'Promotional' */ '@modules/Promotional'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  PEOP: dynamic(() => import(/* webpackChunkName: 'PEOP' */ '../reusable-modules/PEOP'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  FRM0: dynamic(() => import(/* webpackChunkName: 'CFR0' */ '../ot-modules/Contact'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  CAL: dynamic(() => import(/* webpackChunkName: 'CAL' */ '@modules/Catalog/List/ModuleList'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),

  CLT: dynamic(() => import(/* webpackChunkName: 'CLT' */ '@modules/Catalog/Header/ModuleHeader'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  SignIn: dynamic(() => import(/* webpackChunkName: 'SignIn' */ '../reusable-modules/SignIn'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  EVENTS_Browse: dynamic(() => import(/* webpackChunkName: 'EVENTS_Browse' */ '@modules/Events'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  PRESS_SubscribeModule: dynamic(
    () => import(/* webpackChunkName: 'PRESS_SubscribeModule' */ '../ot-modules/Press/SubscribeModule'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),
  PRESS_ImagesModule: dynamic(
    () => import(/* webpackChunkName: 'PRESS_ImagesModule' */ '../ot-modules/Press/ImagesModule'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),
  PressImages: dynamic(() => import(/* webpackChunkName: 'PressImages' */ '../reusable-modules/PressImages/'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  PRESS_FactsFigures: dynamic(
    () => import(/* webpackChunkName: 'PRESS_FactsFigures' */ '../ot-modules/Press/FactsFigures'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),
  MPA_Intro: dynamic(() => import(/* webpackChunkName: 'MPA_Intro' */ '../PressAcc/Intro'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),

  MPA_PersonalDetail: dynamic(() => import(/* webpackChunkName: 'MPA_PersonalDetail' */ '../PressAcc/Profile'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),

  MPA_Application: dynamic(() => import(/* webpackChunkName: 'MPA_Application' */ '../PressAcc/Application'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),

  MPA_Login: dynamic(() => import(/* webpackChunkName: 'MPA_Login' */ '../PressAcc/Login'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),

  MPA_ModalApplicationSubmitted: dynamic(
    () => import(/* webpackChunkName: 'MPA_ModalApplicationSubmitted' */ '../PressAcc/ModalApplicationSubmitted'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),
  UserAccess: dynamic(() => import(/* webpackChunkName: 'SecondaryHeader' */ '../UserAccess'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  UDGI: dynamic(() => import(/* webpackChunkName: 'UDGI' */ '../ot-modules/UDGI'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  PersonalDetail: dynamic(() => import(/* webpackChunkName: 'PersonalDetail' */ '../ot-modules/PersonalDetail'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  MyCollections: dynamic(() => import(/* webpackChunkName: 'MCH' */ '@modules/collections/MyCollections'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  Search: dynamic(() => import(/* webpackChunkName: 'Search' */ '../ot-modules/Search'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  DeleteAccount: dynamic(() => import(/* webpackChunkName: 'DeleteAccount' */ '../ot-modules/DeleteAccount'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  VipProfilingInit: dynamic(() => import(/* webpackChunkName: 'VipProfiling' */ '@modules/VipProfiling'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  GPV: dynamic(() => import(/* webpackChunkName: 'GPV' */ '@modules/GalleryProfile'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  EVENTS_Detail: dynamic(() => import(/* webpackChunkName: 'EVENTS_Detail' */ '../ot-modules/Events/Detail/'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  OneClickRSVP: dynamic(() => import(/* webpackChunkName: 'OneClickRSVP' */ '../ot-modules/OneClickRSVP'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  EMIDS: dynamic(() => import(/* webpackChunkName: 'AOSP' */ '@modules/EMInterviewDetails'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  EMR: dynamic(() => import(/* webpackChunkName: 'AOSP' */ '@modules/EMRecommendedForYou'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  EMHSS: dynamic(() => import(/* webpackChunkName : 'EMHM' */ '@modules/EMHSS'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  EMIM: dynamic(() => import(/* webpackChunkName : 'EMImageMosaic' */ '@modules/EMImageMosaic'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  EMCS: dynamic(() => import(/* webpackChunkName: 'AOSP' */ '@modules/EMCaption'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  VideoM: dynamic(() => import(/* webpackChunkName: 'VideoM' */ '../reusable-modules/VideoM'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  EMVCS: dynamic(() => import(/* webpackChunkName: 'AOSP' */ '@modules/EMVideoCreditSection'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  GRCPM: dynamic(() => import(/* webpackChunkName: 'GRCPM' */ '@modules/Catalog/Preview'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  Introduction: dynamic(() => import(/* webpackChunkName: 'Introduction' */ '../reusable-modules/Introduction'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  VIPD_Disclaimer: dynamic(
    () => import(/* webpackChunkName: 'VIPD_Disclaimer' */ '../ot-modules/VipDesignation/VipDisclaimer'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),
  VIPD_Designation: dynamic(
    () => import(/* webpackChunkName: 'VIPD_Designation' */ '../ot-modules/VipDesignation/VipDesignation'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),
  EventsExhibitionOverview: dynamic(
    () => import(/* webpackChunkName: 'EventsExhibitionOverview' */ '../ot-modules/EventsExhibition'),
    {
      loading: DefaultLoadingComponent,
      ssr: false
    }
  ),
  ACR: dynamic(() => import(/* webpackChunkName: 'PMEM' */ '@modules/inquiries'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  EMPS: dynamic(() => import(/* webpackChunkName: 'AOSP' */ '@modules/EMParagraphSection'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  UserDashboardInvitations: dynamic(
    () => import(/* webpackChunkName: 'UserDashboardInvitations' */ '../ot-modules/UserDashboardInvitations'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),
  invitations: dynamic(() => import(/* webpackChunkName: 'invitations' */ '@modules/invitations'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  Unsubscribe: dynamic(() => import(/* webpackChunkName: 'Unsubscribe' */ '../ot-modules/Unsubscribe'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  PRO2: dynamic(() => import(/* webpackChunkName: 'PRO2' */ '@modules/Catalog/Artists'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  DET: dynamic(() => import(/* webpackChunkName: 'DET' */ '@modules/Catalog/Artworks'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  VOM: dynamic(() => import(/* webpackChunkName: 'ACM' */ '@modules/ownersVouchersModule'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  OneClickInvitation: dynamic(
    () => import(/* webpackChunkName: 'OneClickInvitation' */ '../ot-modules/OneClickInvitation'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),
  EditPreviewCollection: dynamic(
    () => import(/* webpackChunkName: 'CEPM' */ '@modules/collections/EditPreviewCollection'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),

  HMM: dynamic(() => import(/* webpackChunkName: 'RMM' */ '@modules/HotelMapModule'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),

  _nullComponent: dynamic(() => import(/* webpackChunkName: '_nullComponent' */ '../NullComponent'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),

  _noComponent: dynamic(() => import(/* webpackChunkName: '_noComponent' */ '../NoComponent'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),

  Image: dynamic(() => import(/* webpackChunkName: 'Image' */ '@modules/ImageModule'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  SectorExhibitorList: dynamic(
    () => import(/* webpackChunkName: 'Image' */ '../reusable-modules/SectorExhibitorList'),
    {
      loading: DefaultLoadingComponent,
      ssr: true
    }
  ),
  OverviewIntro: dynamic(() => import(/* webpackChunkName : 'CPM' */ '@modules/OverviewIntro'), {
    loading: DefaultLoadingComponent,
    ssr: true
  }),
  Carousel: dynamic(() => import(/* webpackChunkName: 'Carousel' */ '@modules/Carousel'), {
    loading: DefaultLoadingComponent,
    ssr: true
  })
}

/* --------- */
const cmsModuleMap = {
  ACR: { component: 'ACR' },
  EVEU: { component: 'EventsExhibitionOverview' },
  REM: { component: 'REM' },
  BNM: { component: 'PromotionalBanner' },
  Promotional: { component: 'Promotional' },
  Text: { component: 'Text' },
  Content: { component: 'Content' },
  Information: { component: 'Information' },
  Carousel: { component: 'Carousel' },
  Signpost: { component: 'Signpost' },
  QTS: { component: 'QuoteCarousel' },
  BMP: { component: 'FindUs' },
  PAM: { component: 'Partners' },
  PIMG: { component: 'PressImages' },
  News: { component: 'News' },
  OSINTROC: { component: 'OSINTROC' },
  ADAR: { component: 'ADAR' },
  'Login overlay': { component: 'LoginOverlay' },
  'UA - User Access Module': { component: 'UserAccess' },
  'Headline title': { component: 'HeadlineTitle' },
  'Separator line': { component: 'Separator' },
  'Video Player': { component: 'VideoPlayer' },
  'BMP - Basel': { component: 'FindUs' },
  'BMP - Hong Kong': { component: 'FindUs' },
  'BMP - Miami Beach': { component: 'FindUs' },
  'TMPL-Image Module': { component: 'Image' },
  SIGNIN: { component: 'SignIn' },
  RSPF: { component: 'ResetPasswordForm' },
  VIDEOM: { component: 'VideoM' },
  'SEL - Sector Exhibitor List': { component: 'SectorExhibitorList' },
  H2A: { component: 'H2A' },
  PEOP: { component: 'PEOP' },
  PlaygroundInformation: { component: 'PlaygroundInformation' },
  MEMS: { component: 'DeleteAccount' },
  AIP: { component: 'PersonalDetail' },
  INV: { component: 'UserDashboardInvitations' },
  IVI: { component: 'invitations' },
  UCO: { component: 'Unsubscribe' },
  SCH: { component: 'Search' },
  UDGI: { component: 'UDGI' },
  /* -- */
  MPAI: { component: 'MPA_Intro' },
  MPAPD: { component: 'MPA_PersonalDetail' },
  MPAA: { component: 'MPA_Application' },
  MPAL: { component: 'MPA_Login' },
  MPAS: { component: 'MPA_ModalApplicationSubmitted' },
  /* -- */
  'F&F': { component: 'PRESS_FactsFigures' },
  NAR: { component: 'PRESS_Coverage' },
  SPR: { component: 'PRESS_SubscribeModule' },
  LNA: { component: 'PRESS_CoverageModule' },
  LPI: { component: 'PRESS_ImagesModule' },
  /* ---- */
  DCL: { component: 'VIPD_Disclaimer' },
  VIPDs: { component: 'VIPD_Designation' },
  /* ---- */
  PAH: { component: 'PASSES_Header' },
  PCD: { component: 'PASSES_CompanyDetail' },
  'AP - Passes Required': { component: 'PASSES_Required' },
  /* ---- */
  EVS: { component: 'EVENTS_Browse' },
  EVG: { component: 'EVENTS_Group' },
  EVD: { component: 'EVENTS_Detail' },
  TLE: { component: 'EVENTS_BrowseHeader' },
  'Attending Events': { component: 'EVENTS_Invitations_Attending' },
  /* ---- */
  'GENPWD - Generate Password Popup': { component: 'ResetPasswordCRMUser' },
  // 'REP - Reset Password': { component: 'ModalCreateNewPassword' },
  'SIU - Sign Up': { component: 'ModalActivatedAccount' },
  'INVACC - Accept Invitation Popup': { component: 'OneClickInvitation' },
  'INVDEC - Decline Invitation Popup': { component: 'OneClickInvitation' },
  'EVD-RSVP attendance': { component: 'OneClickRSVP' },
  RSVPC: { component: 'OneClickRSVP' },
  FRM0: { component: 'FRM0' },
  /* ---- */
  CAL: { component: 'CAL' },
  CLT: { component: 'CLT' },
  GPV: { component: 'GPV' },
  PRO2: { component: 'PRO2' },
  H3B: { component: 'Introduction' },
  DET: { component: 'DET' },
  'OSINTRO - Online Show Intro': { component: 'OS' },
  VCH: { component: 'Vouchers' },
  MCM: { component: 'MyCollections' },
  CEPM: { component: 'EditPreviewCollection' },
  OSTM: { component: 'OSTM' },
  OWS: { component: 'OWS' },
  EMHSS: { component: 'EMHSS' },
  EMSB: { component: 'EMSB' },
  EMIS: { component: 'EMIS' },
  EMPS: { component: 'EMPS' },
  EMIDS: { component: 'EMIDS' },
  EMCS: { component: 'EMCS' },
  EMR: { component: 'EMR' },
  EMVCS: { component: 'EMVCS' },
  HMM: { component: 'HMM' },
  VIPPROFILINGINIT: { component: 'VipProfilingInit' },
  TSBM: { component: 'TSBM' },
  SKSP: { component: 'SKSP' },
  SECOM: { component: 'SecondaryHeader' },
  GRCPM: { component: 'GRCPM' },
  VOM: { component: 'VOM' },
  // BRM: { component: 'BRM' },
  // DABM: { component: 'DABM' },
  /* ---- */
  // hide modules developed in legacy but not in hybrid
  INVACC: { component: '_nullComponent' },
  INVDEC: { component: '_nullComponent' },
  GENPWD: { component: '_nullComponent' },
  H5B: { component: '_nullComponent' },
  CNF2: { component: '_nullComponent' },
  LPR: { component: '_nullComponent' },
  PRR: { component: '_nullComponent' },
  PRO1: { component: '_nullComponent' },

  // These modules don't need rendering engine, example: Voting wizard
  IVOM: { component: '_nullComponent' },
  AVCM: { component: '_nullComponent' },
  MABM: { component: '_nullComponent' },
  VIPPROFILING: { component: '_nullComponent' },
  TYVM: { component: '_nullComponent' },
  VSCM: { component: '_nullComponent' },
  OverviewIntro: { component: 'OverviewIntro' }
}

interface IRenderableComponent {
  [x: string]: {
    Component: new () => React.Component<any, any>
    component: string
    moduleName: string
  }
}

/* --------- */
const cmsModuleComponents: IRenderableComponent = {}

// Creates an object with the Components to render
Object.keys(cmsModuleMap).map((moduleName) => {
  const componentId = cmsModuleMap[moduleName].component || '_noComponent'
  const Component = cmsComponents[componentId] || null

  const data = { moduleName, Component, ...cmsModuleMap[moduleName] }

  cmsModuleComponents[moduleName] = data

  return data
})

export default cmsModuleComponents
